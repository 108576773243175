<template>
  <v-container class="invitation-request">
    <h1 class="title">
      Tell use about you and why you are interested.
    </h1>
    <v-divider />
    <request-for-invitation-form />
  </v-container>
</template>

<script>
import RequestForInvitationForm from '../../components/invitation/RequestForInvitationForm.vue'

export default {
  components: { RequestForInvitationForm },
  name: "RequestForInvitationView"
}
</script>

<style scoped>
.invitation-request {
  max-width: 600px;
}
</style>