<template>
  <form @submit.prevent="onSubmit">
    <v-row>
      <v-col cols="4">
        <v-subheader>Email</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field
          required
          type="email"
          label="Your Email"
          v-model="invitationForm.email"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-subheader>Message</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-textarea
          required
          name="message"
          label="Your Message"
          v-model="invitationForm.message"
          hint="Tell us about yourself. And why do you want an invitaion?"
        />
      </v-col>
    </v-row>
    <v-row
      class="justify-end"
      no-gutters
    >
      <v-btn
        outlined
        color="primary"
        to="/"
      >
        Back to Login
      </v-btn>
      <v-spacer />
      <v-btn
        type="submit"
        color="info"
        :disabled="loading"
      >
        Ask for Invitation
      </v-btn>
    </v-row>
  </form>
</template>

<script>
export default {
  name: "RequestForInvitationForm",
  data() {
    return {
      invitationForm: {},
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      const response = await this.$store.dispatch(
        "requestForInvitationAction",
        this.invitationForm
      );
      if (response.status == 200) {
        this.invitationForm = {};
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
      } else {
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
      }
    },
  },
};
</script>